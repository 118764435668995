import { ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';

import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Tenant } from '@lib/tenants/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { TableHead as MuiTableHead } from '@mui/material';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import { visuallyHidden } from '@mui/utils';

import { TableCell } from './common/TableCell';
import { TableFilteredHeadCell, TableHeadCell, TableOrder } from './types';

interface Props<T> {
  headCells: TableHeadCell<T>[];
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: TableHeadCell<T>['id']
  ) => void;
  order: TableOrder;
  orderBy: TableHeadCell<T>['id'];
  // For filtering both filterBy and onFilterChange are required
  filterBy?: TableFilteredHeadCell<T>;
  onFilterChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    headCell: TableHeadCell<T>
  ) => void;
}

export const TableHead = <T,>({
  headCells,
  order,
  orderBy,
  onRequestSort,
  filterBy,
  onFilterChange,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation();
  const { tenant } = useTenantProps();
  const dynamicStyles = styles({ specifics: tenant.theme?.specifics });

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id as string | number}
            align={headCell.align ?? 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              ...(index === 0 && {
                borderTopLeftRadius: '16px',
                borderBottomLeftRadius: '16px',
              }),
              ...(index === headCells.length - 1 && {
                borderTopRightRadius: '16px',
                borderBottomRightRadius: '16px',
              }),
              ...dynamicStyles.getValue('headerCell'),
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={(e) => createSortHandler(e, headCell.id)}
              sx={dynamicStyles.getValue('sortLabel')}
            >
              {headCell.formatLabel(t)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {headCell.filterFn && (
              <TextField
                id={headCell.id as string}
                name={headCell.id as string}
                variant={'standard'}
                sx={dynamicStyles.getValue('filterInput')}
                value={getFilterValue(headCell)}
                onChange={(event) => onFilterChange?.(event, headCell)}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );

  function createSortHandler(
    event: React.MouseEvent<unknown>,
    property: TableHeadCell<T>['id']
  ): void {
    onRequestSort(event, property);
  }

  function getFilterValue(headCell: TableHeadCell<T>): string {
    if (filterBy?.headCell.id === headCell.id) {
      return filterBy.filterValue;
    }
    return '';
  }
};

const styles = ({ specifics }: Tenant['theme']) =>
  new SxStyles({
    headerCell: {
      backgroundColor: specifics?.authTicketTable?.headerBackgroundColor,
      color: 'text.primary',
      border: 'none',
    },
    sortLabel: {
      '&.Mui-active': {
        color: 'text.primary',

        '.MuiTableSortLabel-icon': {
          color: 'text.primary',
        },
      },
      '&:hover': {
        color: 'text.secondary',

        '.MuiTableSortLabel-icon': {
          color: 'text.secondary',
        },
      },
    },
    filterInput: {
      '& .MuiInputBase-input': {
        borderRadius: 1,
        backgroundColor: 'common.white',
        padding: (theme) => theme.spacing(0, 1, 0, 1),
      },
    },
  });
