import { format, parseISO } from 'date-fns';
import * as locales from 'date-fns/locale';

export const getDateFormatFromLocale = (
  tenantLocale = 'en-US',
  dateFormatType:
    | 'Date'
    | 'DayMonth'
    | 'DateWithTime'
    | 'DateWithPreciseTime' = 'Date'
): string => {
  const dateFnsLocale = Object.values(locales).find((locale) => {
    const country = locale?.code?.substring(locale?.code?.length - 2);
    if (country && tenantLocale.toLowerCase().includes(country.toLowerCase())) {
      return locale;
    }

    return false;
  });

  const dateFnsFormat: string = getLocale(
    dateFnsLocale?.code ?? 'en-US'
  ).formatLong?.date({
    width: 'short',
  });
  const yearIndex = dateFnsFormat.indexOf('y');
  const dateFormat = dateFnsFormat.replaceAll('y', '');

  switch (dateFormatType) {
    case 'Date': {
      if (yearIndex) {
        return dateFormat + 'yyyy';
      } else {
        return 'yyyy' + dateFormat;
      }
    }
    case 'DayMonth': {
      return yearIndex ? dateFormat.slice(0, yearIndex - 1) : dateFormat;
    }
    case 'DateWithTime': {
      const timeFnsFormat: string = getLocale(
        dateFnsLocale?.code ?? 'en-US'
      ).formatLong?.time({
        width: 'short',
      });
      if (yearIndex) {
        return `${dateFormat}yyyy ${timeFnsFormat}`;
      } else {
        return `yyyy${dateFormat} ${timeFnsFormat}`;
      }
    }
    case 'DateWithPreciseTime': {
      const timeFnsFormat: string = getLocale(
        dateFnsLocale?.code ?? 'en-US'
      ).formatLong?.time({
        width: 'medium',
      });
      if (yearIndex) {
        return `${dateFormat}yyyy ${timeFnsFormat}`;
      } else {
        return `yyyy${dateFormat} ${timeFnsFormat}`;
      }
    }
  }
};

function getLocale(locale: string) {
  return require(`date-fns/locale/${locale}/index.js`);
}

export const normalizeDate = (date: Date): Date =>
  new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));

export const formatDate = (
  date: Date | string,
  dateFormat:
    | 'Date'
    | 'DayMonth'
    | 'DateWithTime'
    | 'DateWithPreciseTime' = 'Date',
  locale = 'en-US'
): string => {
  try {
    const value = date instanceof Date ? date.toISOString() : date;
    const localeFormat = getDateFormatFromLocale(locale, dateFormat);

    return typeof value === 'string'
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        format(normalizeDate(parseISO(value))!, localeFormat)
      : '';
  } catch (e) {
    return '';
  }
};
