import { FC } from 'react';

import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { TableCell as MaterialTableCell, TableCellProps } from '@mui/material';

type Props = TableCellProps;

export const TableCell: FC<Props> = (props) => {
  const { sx, ...restProps } = props;

  return (
    <MaterialTableCell sx={styles.merge<false>('root', sx)} {...restProps} />
  );
};

const styles = new SxStyles({
  root: {
    border: (theme) => `1px solid ${theme.palette.light.main}`,
    backgroundColor: SHARED_PALETTE.grey,
  },
});
