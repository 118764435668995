import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, TableRow } from '@mui/material';
import MaterialTableBody from '@mui/material/TableBody';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';

import { TableCell } from '../common/TableCell';
import { TableHeadCell, TableOrder } from '../types';
import { ReactNode } from 'react';

interface Props<T> {
  rows: T[];
  headCells: TableHeadCell<T>[];
  order: TableOrder;
  orderBy: TableHeadCell<T>['id'];
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
}

export const TableBody = <T,>({
  rows,
  headCells,
  order,
  orderBy,
  page,
  rowsPerPage,
  isLoading,
}: Props<T>): JSX.Element => {
  const { tenant } = useTenantProps();
  const sliceStart = page * rowsPerPage;
  const sliceEnd = page * rowsPerPage + rowsPerPage;
  const dynamicStyles = tenant.theme.specifics;

  if (isLoading) return <CenteredLoader />;

  return (
    <MaterialTableBody sx={styles.getValue('body')}>
      <TableRow>
        {!!rows.length && (
          <TableCell
            sx={{
              backgroundColor: 'light.main',
              border: 'none',
              p: 1,
            }}
            colSpan={12}
          ></TableCell>
        )}
      </TableRow>
      {[...rows]
        .sort(getComparator<T>(order, orderBy))
        .slice(sliceStart, sliceEnd)
        .map((row, index) => tableRowsMap(row, index, rows.length - 1))}
    </MaterialTableBody>
  );

  function tableRowsMap(
    row: T,
    index: number,
    lastRowIndex: number,
  ): JSX.Element {
    const keys = headCells.map((headCell) => headCell.id);

    return (
      <TableRow hover tabIndex={-1} key={index}>
        {keys.map((key, j) => cellValueMap(key, j, row, index, lastRowIndex))}
      </TableRow>
    );
  }

  function cellValueMap(
    key: keyof T,
    index: number,
    row: T,
    rowIndex: number,
    lastRowIndex: number,
  ): JSX.Element {
    const headCell = headCells.find((headCell) => headCell.id === key);
    const borderColor =
      dynamicStyles?.authTicketTable?.tableBodyCellBorderColor ?? '#DCDCDC';
    return (
      <TableCell
        align="left"
        key={index}
        component={Box}
        sx={{
          backgroundColor: '#FFF',
          p: 0,
          height: '60px',
        }}
      >
        <Box
          sx={{
            ...(index === 0 &&
              rowIndex === 0 && {
                borderTopLeftRadius: '16px',
              }),
            ...(rowIndex === 0 && {
              borderTop: `1px solid ${borderColor}`,
            }),
            ...(index === 0 && { borderLeft: `1px solid ${borderColor}` }),
            ...((rowIndex + 1 === rowsPerPage || rowIndex === lastRowIndex) && {
              borderBottom: `1px solid ${borderColor}`,
            }),
            ...((rowIndex + 1 === rowsPerPage || rowIndex === lastRowIndex) &&
              index === 0 && {
                borderBottomLeftRadius: '16px',
              }),
            ...(index === headCells.length - 1 && {
              borderRight: `1px solid ${borderColor}`,
            }),
            ...((rowIndex + 1 === rowsPerPage || rowIndex === lastRowIndex) &&
              index === headCells.length - 1 && {
                borderBottomRightRadius: '16px',
              }),
            ...(rowIndex === 0 &&
              index === headCells.length - 1 && {
                borderTopRightRadius: '16px',
              }),
            padding: 2,
            width: 'inherit',
            height: 'inherit',
          }}
        >
          {!!headCell?.formatRowValue
            ? String(headCell.formatRowValue(row))
            : (row[key] as ReactNode)}
        </Box>
      </TableCell>
    );
  }
};

function descendingComparator<T>(a: T, b: T, orderBy: TableHeadCell<T>['id']) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator<T>(
  order: TableOrder,
  orderBy: TableHeadCell<T>['id'],
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const styles = new SxStyles({
  body: {
    position: 'relative',
  },
});
